* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol, ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #121212;
}
